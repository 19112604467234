<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-4">
      <div v-if="elementVisible">
        <vue-apex-charts
          type="bar"
          height="500"
          :options="dashboardOptions.length > 0 ? dashboardOptions[0] : {}"
          :series="series"
        ></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'CumplimientoXArea',
  props: {
    datos: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    series: [
      {
        name: 'SI',
        data: []
      },
      {
        name: 'POR VENCER',
        data: []
      },
      {
        name: 'NO',
        data: []
      }
    ],
    chartOptions: {
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 3,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '48%',
          endingShape: 'flat'
        }
      },
      colors: ['#27A300', '#02a99e', '#d90429'],
      fill: {
        type: 'solid',
        opacity: 1
      },
      chart: {
        stacked: true,
        toolbar: {
          show: true
        },
        sparkline: {
          enabled: false
        }
      },
      title: {
        text: 'CANTIDAD DE COMPROMISOS POR CUMPLIMIENTO POR ÁREA',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: 'category',
        categories: [],
        labels: {
          trim: true,
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      stroke: {
        curve: 'straight',
        width: 0
      },
      tooltip: {
        theme: 'dark'
      }
    },
    elementVisible: false,
    dashboardOptions: []
  }),
  watch: {
    datos(val) {
      let areas = [];
      let areasaux = [];
      let series = [
        {
          name: 'SI',
          data: []
        },
        {
          name: 'POR VENCER',
          data: []
        },
        {
          name: 'NO',
          data: []
        }
      ];
      if (val.areas?.length > 0) {
        val.areas.forEach((el) => {
          areas.push(el.descripcion.split(' '));
          areasaux.push(el.descripcion);
          series[0].data.push(0);
          series[1].data.push(0);
          series[2].data.push(0);
        });
        this.dashboardOptions.push({
          ...this.chartOptions,
          ...{
            xaxis: {
              type: 'category',
              categories: areas,
              labels: {
                trim: true,
                style: {
                  fontSize: '10px',
                  fontWeight: 'bolder',
                  cssClass: 'text-muted fill-color'
                }
              }
            }
          }
        });
        if (val.acuerdossubareas.length > 0) {
          val.acuerdossubareas.forEach((el) => {
            areasaux.forEach((elem, index) => {
              if (el.descripcion.trim().toUpperCase() == elem.trim().toUpperCase()) {
                series[0].data[index] = series[0].data[index] + parseInt(el.totalsi);
                series[1].data[index] = series[1].data[index] + parseInt(el.totalporvencer);
                series[2].data[index] = series[2].data[index] + parseInt(el.totalno);
              }
            });
          });
        }
        this.elementVisible = true;
      }
      this.series = series;
    }
  },
  created() {},
  components: {
    VueApexCharts
  }
};
</script>
